import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
`;

export const MyContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  height: 50vh;
  width: 100%;

  @media (max-width: 780px) {
    margin-left: 0;
  }
`;

export const StyledHeader = styled.p`
  font-weight: ${(props) => (props.variant === "outline" ? "400" : "700")};
  font-size: ${(props) => (props.variant === "outline" ? "20px" : "64px")};
  line-height: ${(props) => (props.variant === "outline" ? "30px" : "96px")};
  color: #fafafa;
  margin: ${(props) => (props.variant === "outline" ? "0px" : "0px")};
`;

export const CardImgs = styled.img`
  height: 434px;
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
  filter: grayscale(100%);

  @media (max-width: 780px) {
    height: 324px;
    justify-content: center;
  }
`;