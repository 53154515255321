import React from "react";
import { MyNav, MyVector, SubLink } from "./BlogPgNav.styled";
import firstVector from "../../assets/svg/Vector.svg";
import secondVector from "../../assets/svg/Vector2.svg";

const BlogPgNav = () => {
  return (
    <div>
      <MyNav>
        <MyVector>
          <img src={firstVector} alt="an image of a vector" />
        </MyVector>
        <SubLink>1</SubLink>
        <SubLink>2</SubLink>
        <SubLink>3</SubLink>
        <SubLink>4</SubLink>
        <SubLink>5</SubLink>
        <SubLink>6</SubLink>
        <SubLink>7</SubLink>
        <SubLink>8</SubLink>
        <SubLink>9</SubLink>
        <SubLink>10</SubLink>
        <MyVector>
          <img src={secondVector} alt="an image of a vector" />
        </MyVector>
      </MyNav>
    </div>
  );
};

export default BlogPgNav;
