import styled from "styled-components";

export const LeaderBoardContainer = styled.div`
  max-width: 52rem;
`;

export const LeaderBoardWrapper = styled.div`
  overflow-x: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    padding-right: 2rem;
  }
`;

export const LeaderBoardInner = styled.div`
  display: inline-block;
  overflow: hidden;
  min-width: 100%;
`;

export const LeaderBoardTable = styled.table`
  line-height: 1.5;
  min-width: 100%;
  border-spacing: 0px;
`;

export const LeaderBoardTableTitle = styled.th`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: left;
  border-bottom: 2px solid #ffce3d;

  @media (min-width: 768px) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`;

export const LeaderBoardTableData = styled.td`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-bottom: 1px solid #ffce3d;
`;

export const LeaderBoardNumberData = styled.p`
    text-gray-100
`;

export const LeaderBoardStringContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LeaderBoardImageContainer = styled.div`
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
`;

export const LeaderBoardImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
`;

export const LeaderBoardStringData = styled.div`
  margin-left: 0.75rem;
  color: #f3f4f6;
`;
