import styled from "styled-components";

export const BlogInfo = styled.div`
  width: 20%;
  color: white;
  h1 {
    font-weight: 600;
    font-size: 24px;
    height:50px;
    margin-bottom:25px;
    @media (max-width:780px){
      height:70px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 780px) {
    width: 40%;
  }
`;

export const BlogImg = styled.img`
  width: 100%;
`;

export const BlogsCat = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  margin: 3rem 0;

  @media (max-width: 780px) {
    gap: 1.5rem;
  }
`;
export const BlogsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;

  @media (max-width: 780px) {
    gap: 1.5rem;
  }
`;

export const BlogsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;

  div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
  }
`;

export const BlogsCard = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  height: 9rem;
  border-radius: 0.5rem;

  @media (min-width: 768px) {
    height: 15rem;
  }
`;

export const BlogsImage = styled.img`
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BlogsHeading = styled.h2`
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
`;

export const BlogsSubheading = styled.p`
  margin-bottom: 2rem;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`;
