import React from "react";
import { Button } from "../../components";
import { Close } from "./PaymentModal.styled";
import close from "../../assets/svg/x-regular-24.png";

 
import { usePaystackPayment } from "react-paystack";

export default function PayForm({ amt, data, setData, setSuccess, success, modal, setPay, votes }) {
  const config = {
    reference: new Date().getTime().toString(),
    email: data.email,
    amount: amt * 100,
    publicKey: "pk_test_56e002923ed105fe237fa9ebd634bcb301cd7744",
  };
  const onSuccess = (reference) => {
    console.log(reference);
    setSuccess(!success)
  };
  const onClose = () => {
    console.log("closed");
  };
  const  initializePayment  = usePaystackPayment(config);

 
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      initializePayment(onSuccess, onClose);
    };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <Close onClick={() => setPay(!modal)}>
           <img src={close} alt=""/>
          </Close>
          <label htmlFor="">Email:</label>
          <input type="email" required name="email" onChange={handleChange} />
        </div>
        <p>Amount:{amt}</p>
        <Button bg="black" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}
