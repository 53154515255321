import React, { useState } from "react";
import {
  Backdrop,
  Header,
  HeaderContent,
  HeaderInner,
  HeaderLogo,
  MenuButton,
  MenuIcon,
  MobileCopyright,
  MobileFooter,
  MobileFooterInner,
  MobileHeader,
  MobileLogo,
  MobileNavbar,
  MobileNavContainer,
  MobileNavInner,
  MobileNavLinks,
  MobileNavList,
  MobilePrimarybtn,
  MobileSecondarybtn,
  Navbar,
  NavbarBtnWrapper,
  NavbarHeader,
  NavbarLinks,
} from "./MenuBar.styled";
import logo from "../../assets/images/miss_emerald_logo.png";
import openIcon from "../../assets/svg/menu-regular-24.png";
import closeIcon from "../../assets/svg/x-regular-24.png";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

function MenuBar() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* for desktop screens */}
      <Header>
        <HeaderInner>
          <HeaderContent>
            <HeaderLogo>
              <a href="/">
                <img width="15%" src={logo} alt="" />
              </a>
            </HeaderLogo>

            <Navbar>
              <NavbarLinks href="/home">Home</NavbarLinks>
              <NavbarLinks href="/contests">Contest</NavbarLinks>
              <NavbarLinks href="/statistics">LeaderBoard</NavbarLinks>
              <NavbarLinks href="/blog">Blog</NavbarLinks>
              <NavbarLinks href="/about">About</NavbarLinks>
              <NavbarLinks href="/#sponsors">Sponsors</NavbarLinks>
            </Navbar>

            <NavbarBtnWrapper>
              <Button
                bg="white"
                onClick={() =>
                  window.open("https://forms.gle/RRkf2P4NJsHSkEfm6")
                }
              >
                Become a delegate
              </Button>

              <Button bg="black" onClick={() => navigate("/home")}>
                Vote
              </Button>
            </NavbarBtnWrapper>

            <MenuIcon>
              <MenuButton onClick={toggleNav}>
                {isOpen ? (
                  <img src={closeIcon} width="70%" alt="" />
                ) : (
                  <img src={openIcon} width="70%" alt="" />
                )}
              </MenuButton>
            </MenuIcon>
          </HeaderContent>
        </HeaderInner>
      </Header>

      {isOpen && (
        <MobileHeader>
          <Backdrop />
          <MobileNavbar>
            <NavbarHeader>
              <MobileLogo>
                <a href="/">
                  <img width="18%" src={logo} alt="" />
                </a>
              </MobileLogo>
              <MenuButton onClick={toggleNav}>
                {isOpen ? (
                  <img src={closeIcon} width="140%" alt="" />
                ) : (
                  <img src={openIcon} width="140%" alt="" />
                )}
              </MenuButton>
            </NavbarHeader>

            <MobileNavContainer>
              <MobileNavInner>
                <MobileNavList>
                  <MobileNavLinks href="/home">Home</MobileNavLinks>
                </MobileNavList>
                <MobileNavList>
                  <MobileNavLinks href="/contest">Contest</MobileNavLinks>
                </MobileNavList>
                <MobileNavList>
                  <MobileNavLinks href="/statistics">
                    Leaderboard
                  </MobileNavLinks>
                </MobileNavList>
                <MobileNavList>
                  <MobileNavLinks href="/blog">Blog</MobileNavLinks>
                </MobileNavList>
                <MobileNavList>
                  <MobileNavLinks href="/about">About</MobileNavLinks>
                </MobileNavList>
              </MobileNavInner>
            </MobileNavContainer>

            <MobileFooter>
              <MobileFooterInner>
                <MobilePrimarybtn
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/RRkf2P4NJsHSkEfm6"
                >
                  Become a delegate
                </MobilePrimarybtn>
                <MobileSecondarybtn href="/home">Vote</MobileSecondarybtn>
              </MobileFooterInner>

              <MobileCopyright>
                <span>Copyright © 2023</span>
              </MobileCopyright>
            </MobileFooter>
          </MobileNavbar>
        </MobileHeader>
      )}
    </>

    // for mobile screens
  );
}

export default MenuBar;
