import React, { useRef } from "react";
import contest from "../../assets/svg/contest.svg";
import image2 from "../../assets/images/banner2.png";
import image3 from "../../assets/images/banner3.png";
import image4 from "../../assets/images/banner4.jpeg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import {
  BannerWrapper,
  BannerInner,
  BannerContainer,
  BannerButton,
  BannerButton2,
  BannerText,
  CrownImage,
  Text,
} from "./AdsBanner.styled";

// Import Swiper styles
import "swiper/css";

function AdsBanner() {
  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  return (
    <BannerWrapper>
      <BannerInner>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Text>Ads</Text>
          <Text className="ad-num"></Text>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            ref={swiperRefLocal}
            pagination={{
              el: ".ad-num",
              type: "fraction",
              renderCustom: function (swiper, current, total) {
                return current + "/" + (total - 1);
              },
            }}
            autoplay={{
              delay: 1500,
            }}
            modules={[Autoplay, Pagination]}
          >
            <SwiperSlide>
              <BannerContainer>
                <CrownImage src={contest} alt="" />
                <div>
                  <BannerText>MISS EMERALD GHANA</BannerText>
                  <BannerButton2>THE MAIDEN EDITION</BannerButton2>
                </div>
                <a
                  href="https://forms.gle/N2P1wfrRpu9s5Ae79"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BannerButton>Register</BannerButton>
                </a>
              </BannerContainer>
            </SwiperSlide>

            <SwiperSlide>
              <BannerContainer style={{ backgroundImage: `url(${image2})` }}>
                <div>
                  <BannerText style={{ color: "#000" }}>Banner 1</BannerText>
                  <BannerButton>Get Started</BannerButton>
                </div>
              </BannerContainer>
            </SwiperSlide>

            <SwiperSlide>
              <BannerContainer style={{ backgroundImage: `url(${image4})` }}>
                <div>
                  <BannerText>Banner 2</BannerText>
                  <BannerButton>Get Offer</BannerButton>
                </div>
              </BannerContainer>
            </SwiperSlide>

            <SwiperSlide>
              <BannerContainer style={{ backgroundImage: `url(${image3})` }}>
                <div>
                  <BannerText style={{ color: "#000" }}>Banner 3</BannerText>
                  <BannerButton>Start Now</BannerButton>
                </div>
              </BannerContainer>
            </SwiperSlide>
          </Swiper>
        </div>
      </BannerInner>
    </BannerWrapper>
  );
}

export default AdsBanner;
