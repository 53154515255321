import React from "react";
import { useState } from "react";
import { Wrapper, HeadWrapper } from "../../pages/Home/Home.styled";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import Button from "../Button/Button";
import SupportButton from "../Support/SupportButton";
import Footer from "../Footer/Footer";
import { ReactComponent as Backhand } from "../../assets/svg/back_hand.svg";
import {
  VoteHeader,
  Token,
  VoteCard,
  VoteNo,
  Price,
  VotesContainer,
} from "./Vote.styled";
import token from "../../assets/images/token.png";
import { counts } from "../../User";
import { PaymentModal } from "../../HOC";
import { useParams, Link } from "react-router-dom";
import data from "../../User";

export default function Vote() {
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const { contestantId } = useParams();
  const contestant = data.find((contestant) => contestant.id === contestantId);
  const { name } = contestant;

  const handleShowModal = (amount) => {
    setModal(!modal);
    setAmount(amount);
  };

  return (
    <div>
      <SideBar />
      <HeadWrapper>
        <Header />
      </HeadWrapper>
      <VoteHeader>
        <h1>Votes</h1>
        <Button bg="black" onClick={() => window.history.back()}>
          <Backhand /> Back
        </Button>
      </VoteHeader>
      <Wrapper>
        <VotesContainer>
          <PaymentModal
            setModal={setModal}
            modal={modal}
            name={name}
            amt={amount}
          />
          {counts.map((vote) => {
            return (
              <VoteCard key={vote.id} onClick={() => handleShowModal(vote.amt)}>
                <Token src={token} alt="" />
                <VoteNo>{vote.amt} Votes</VoteNo>
                <Price>GHS {vote.amt}</Price>
              </VoteCard>
            );
          })}
        </VotesContainer>
      </Wrapper>
      <SupportButton />
      <Footer />
    </div>
  );
}
