import styled from "styled-components";
import { ReactComponent as CurvedLine } from "../../assets/svg/curved-line.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 3rem;
  @media (max-width: 780px) {
    font-size: 2.5rem;
  }

  @media (max-width: 425px) {
    font-size: 1.8rem;
  }
`;

const Line = styled(CurvedLine)`
  width: 10rem;
  @media (max-width: 780px) {
    width: 7rem;
  }

  @media (max-width: 425px) {
    width: 6rem;
  }
`;

export { Title, Container, Line };
