import { StyledBlog, BlogDiv, Heading,
     SmallerHeading, Avatar
     , Details, BlogHeading, BlogParagraph
     , BlogSocial, BlogFooter, Facebook
     , Twitter, Share } from "./BlogHeader.styled";
import avatar from "../../assets/images/img0.jpg";
import facebook from "../../assets/svg/facebook.svg";
import twitter from "../../assets/svg/twitter.svg";
import whatsapp from "../../assets/svg/whatsapp.svg";
import share from "../../assets/svg/share.svg";

export default function BlogHeader() {
    return(
        <StyledBlog>
        <Heading>
            Latest News
        </Heading>
        <SmallerHeading>
        For post engagement or traffic please fill out <a href="#">this form</a>
        </SmallerHeading>
        <BlogDiv>
            <div>
            <Avatar src={avatar} alt="MPEG" />
            </div>
            <Details>
                <BlogHeading>Blog title goes here...</BlogHeading>
                <BlogParagraph>Mavis is a student of life who believes in togetherness as 
                the key to bridge the gap between people and societies. 
                According to her, the best way to get inspired is by
                watching relatable examples find success. Therefore, she
                became the first in her family to graduate with a Master of 
                Science degree in a third language. Mavis is a student of
                lifee the first in her family to graduate with a Master of
                Science degree in a third language. Mavis is a student of
                lifee the first in her family to graduate with a Master of
                Science degree in a third language.</BlogParagraph>
                <BlogFooter>
                    <time dateTime="#">Monday 16, 2023</time> <span>.</span> <time dateTime="8:00">8:00AM</time>
                  <BlogSocial>
                    <a href="#"><Facebook src={facebook} alt="MPEG" /></a>
                    <a href="#"><Twitter src={twitter} alt="MPEG" /></a>
                    <a href="#"><Facebook src={whatsapp} alt="MPEG" /></a>
                    <a href="#"><Share src={share} alt="MPEG" /></a>
                  </BlogSocial>
                </BlogFooter>
            </Details>
        </BlogDiv>
        </StyledBlog>
    )
}