import styled from "styled-components";

const Navbar = styled.div`
  color: white;
  display: flex;
  gap: 3rem;
  margin-top: 3rem;

  h2 {
    margin: 0;
    font-weight: 400;
    color: #b0b0b0;
    cursor: pointer;
    padding-bottom: 0.25rem;
  }
  h2:hover,
  h2.selected {
    font-weight: 600;
    color: white;
    padding-bottom: 0.2rem;
    border-bottom: 0.1rem solid #ffce3d;
  }

  @media (max-width: 786px) {
    font-size: 0.6rem;
  }

  @media (max-width: 375px) {
    gap: 1rem;
  }
`;

export { Navbar };
