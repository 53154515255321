import React, { useState } from "react";
import { Header, ContestantsCard, SideBar, Footer } from "../../components";
import SupportButton from "../../components/Support/SupportButton";
import ContestsNav from "../../HOC/ContestsNav/ContestsNav";
import { Wrapper, HeadWrapper } from "../Home/Home.styled";
import { Eviction } from "../../HOC";
import data from "../../User";

export default function Contestants() {
  const [contests, setContests] = useState(true);
  function onContest() {
    setContests(true);
  }
  function onEvict() {
    setContests(false);
  }

  const [contestants, setContestants] = useState(data);
  const searchContestants = (keyword) => {
    if (!keyword) {
      setContestants(data);
      return;
    }
    keyword = keyword.toLocaleLowerCase();
    setContestants(
      data.filter((c) => c.name.toLocaleLowerCase().includes(keyword))
    );
  };
  return (
    <div>
      <SideBar page="contests" />
      <HeadWrapper>
        <Header onSearch={searchContestants} />
        <ContestsNav
          onContest={onContest}
          onEvict={onEvict}
          selected={contests ? "contests" : "evicted"}
        />
      </HeadWrapper>
      <Wrapper>
        {contests ? <ContestantsCard data={contestants} /> : <Eviction />}
        <SupportButton />
      </Wrapper>
      <Footer />
    </div>
  );
}
