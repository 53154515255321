import React from "react";
import { Container, Title, Line } from "./LandingPgHeading.styled";

const LandingPgHeading = ({ text }) => {
  return (
    <Container>
      <Title>{text}</Title>
      <Line />
    </Container>
  );
};

export default LandingPgHeading;
