import styled from "styled-components";

const Container = styled.div`
  width: 30rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  div + p {
    margin-top: 1rem;
  }

  p span {
    font-weight: 600;
  }

  p {
    margin: 0;
    @media (min-width: 780px) {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 780px) {
    width: 23rem;
    gap: 1.5rem;
  }

  @media (max-width: 685px) {
    width: unset;
    margin: 0 1rem;
    align-items: center;
  }
`;

export { Container };
