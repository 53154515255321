import React from "react";
import youtube from "../../assets/svg/youtube.svg";
import instagram from "../../assets/svg/instagram.svg";
import heart from "../../assets/svg/heart.svg";
import Logo from "../../assets/images/miss_emerald_logo.png";
import {
  CopyrightText,
  FooterBackground,
  FooterContainer,
  FooterContent,
  FooterGrid,
  FooterHeading,
  FooterHeartIcon,
  FooterLink,
  FooterSocialContainer,
  FooterSocialIcons,
  FooterSubHeading,
  LogoContainer,
  LogoInner,
} from "./Footer.styled";

export default function Footer({ full }) {
  return (
    <>
      <FooterBackground>
        <FooterContainer>
          {/* For a footer with full content  the full attribute is added to the footer component*/}

          {full ? (
            <>
              <FooterGrid>
                <div>
                  {/* <!-- logo - start --> */}
                  <LogoContainer>
                    <LogoInner href="/">
                      <img src={Logo} alt="Logo" width={"45%"} />
                    </LogoInner>
                  </LogoContainer>
                  {/* <!-- logo - ends --> */}
                </div>

                <div>
                  <FooterHeading>MENU</FooterHeading>

                  <FooterContent>
                    <div>
                      <FooterSubHeading
                        target="_blank"
                        rel="noreferrer"
                        href="https://forms.gle/RRkf2P4NJsHSkEfm6"
                      >
                        Become A Delegate
                      </FooterSubHeading>
                    </div>

                    <div>
                      <FooterSubHeading href="/contests">
                        Contest
                      </FooterSubHeading>
                    </div>

                    <div>
                      <FooterSubHeading href="/blog">Blog</FooterSubHeading>
                    </div>

                    <div>
                      <FooterSubHeading href="/about">About</FooterSubHeading>
                    </div>
                  </FooterContent>
                </div>

                <div>
                  <FooterHeading>Address</FooterHeading>

                  <FooterContent>
                    <div>
                      <FooterSubHeading inactive={true} href="#">
                        Accra, Ghana
                      </FooterSubHeading>
                    </div>

                    <div>
                      <FooterSubHeading href="tel:+233574752451">
                        +233 (0) 57 475 2451
                      </FooterSubHeading>
                    </div>

                    <div>
                      <FooterSubHeading href="tel:+233574752451">
                        +233 (0) 57 475 2451
                      </FooterSubHeading>
                    </div>

                    <div>
                      <FooterSubHeading href="mailto:missemeraldghana@gmail.com">
                        missemeraldghana@gmail.com
                      </FooterSubHeading>
                    </div>
                  </FooterContent>
                </div>

                <div>
                  <FooterHeading>CUSTOM ADS</FooterHeading>

                  <FooterContent>
                    <div>
                      <FooterLink
                        target="_blank"
                        rel="noreferrer"
                        href="https://forms.gle/N2P1wfrRpu9s5Ae79"
                      >
                        Banner Ads
                      </FooterLink>
                    </div>

                    <div>
                      <FooterLink
                        target="_blank"
                        rel="noreferrer"
                        href="https://forms.gle/N2P1wfrRpu9s5Ae79"
                      >
                        Blog Ads
                      </FooterLink>
                    </div>
                  </FooterContent>
                </div>
              </FooterGrid>

              <FooterSocialContainer>
                <FooterSocialIcons src={youtube} alt="youtube icon" />
                <FooterSocialIcons src={instagram} alt="instagram icon" />
              </FooterSocialContainer>

              <CopyrightText>
                All copyright reserved for Miss Emerald Ghana 2023 - 2024 |
                Design with <FooterHeartIcon src={heart} alt="heart Icon" />{" "}
                <FooterLink
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/fleetlabsgh/"
                >
                  by Fleet Labs Ghana
                </FooterLink>
              </CopyrightText>
            </>
          ) : (
            <>
              <FooterSocialContainer
                style={{ paddingTop: "2rem", marginTop: "2rem" }}
              >
                <FooterSocialIcons src={youtube} alt="youtube icon" />
                <FooterSocialIcons src={instagram} alt="instagram icon" />
              </FooterSocialContainer>

              <CopyrightText>
                All copyright reserved for Miss Emerald Ghana 2023 - 2024 |
                Design with <FooterHeartIcon src={heart} alt="heart Icon" />{" "}
                <FooterLink
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/fleetlabsgh/"
                >
                  by Fleet Labs Ghana
                </FooterLink>
              </CopyrightText>
            </>
          )}
        </FooterContainer>
      </FooterBackground>
    </>
  );
}
