import styled from "styled-components";

const Content = styled.div`
  margin: 4rem 0;
  color: white;
`;

const DataLabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.3rem 1.2rem;
  margin-top: 1rem;
  p {
    font-size: 0.8rem;
    margin: 0;
  }
`;

const DataLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
`;

const LabelColor = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export { Content, DataLabelsWrapper, DataLabel, LabelColor };
