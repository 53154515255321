import styled from "styled-components";

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  height: 50vh;
  color: #ffffff;
  padding: 70px;
  & span {
    border-bottom: 1px solid #fafafa;
  }
`;
export const Social = styled.div`
  display: flex;
  column-gap: 40px;
`;

export const Text = styled.h6`
  display: flex;
  align-items: centenpm star;
  column-gap: 0.4rem;
`;

export const FooterBackground = styled.div`
  background-color: #101010;
`;

export const FooterContainer = styled.footer`
  padding-top: 0.5rem;
  padding-bottom: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;

  @media (min-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const FooterGrid = styled.div`
  display: grid;
  padding-top: 2.5rem;
  margin-bottom: 4rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 3rem;

  @media (min-width: 1024px) {
    padding-top: 3rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    margin-top: -0.5rem;
  }
`;

export const LogoInner = styled.a`
  display: inline-flex;
  color: #f3f4f6;
  font-size: 1.25rem;
  line-height: 1.75rem;
  line-height: 2rem;
  font-weight: 700;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const FooterHeading = styled.div`
  margin-bottom: 1rem;
  color: #f3f4f6;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const FooterContent = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FooterSubHeading = styled.a`
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 100ms;
  color: #d1d5db;

  ${(props) =>
    props.inactive ? { pointerEvents: "none", cursor: "default" } : null}

  :hover {
    color: #f3f4f6;
    text-decoration: underline;
  }
`;

export const FooterSocialContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const FooterSocialIcons = styled.img`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const CopyrightText = styled.div`
  color: #f3f4f6;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 500;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
`;

export const FooterHeartIcon = styled.img`
  vertical-align: middle;
  display: inline-block;
  width: 18px;

  @media (min-width: 768px) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    width: 24px;
  }
`;

export const FooterLink = styled.a`
  color: #d1d5db;
  text-decoration: underline;
  text-transform: none;
  font-weight: 100;

  :hover {
    color: #fff;
  }
`;
