import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    justify-content: center;
    padding: 0 6rem;
    @media (max-width: 780px) {
      padding: 0;
    }
  }

  @media (max-width: 780px) {
    margin-bottom: 8rem;
  }
`;

const HeadWrapper = styled.div`
  margin: 2rem 7rem 2rem;
  @media (max-width: 640px) {
    margin: 1rem 1rem;
  }

  @media (min-width: 1265px) {
    margin-left: 8rem;
  }

  @media (min-width: 1300px) {
    margin-left: 9rem;
  }

  @media (min-width: 1440px) {
    margin-left: 13rem;
  }

  @media (min-width: 2560px) {
    margin-left: 15rem;
  } ;
`;

export { Wrapper, HeadWrapper };
