import styled from "styled-components";


export const StyledBlog = styled.div`
@media(max-width: 768px) {
    display: flex;
    gap:1rem;
    justify-content:center;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
}
`


export const BlogDiv = styled.div`
display: flex;
align-items: center;
justify-content: space-between;


@media(max-width: 768px) {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
}
`


export const Heading = styled.h2`
font-family: Poppins;
font-size: 52px;
font-weight: 600;
line-height: 84px;
letter-spacing: 0px;
color: #FAFAFA;
text-align: center;
`

export const SmallerHeading = styled.h4`
font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0px;
text-align: center;
color: #FAFAFA;

a{
    color: #FFBB1C;
    text-decoration: underline;
}

`

export const Avatar = styled.img`
height: 450px;
width: 450px;
margin-right:70px;
border-radius: 8px;
background: #BFBFBF;

@media(max-width: 768px) {
    height: 90%;
    width: 90%;
    margin: 0px 20px;
    padding: 0px;
}
`

export const Details = styled.div`
display:flex;
flex-direction:column;
gap:2rem;
`


export const BlogHeading = styled.h1`
font-family: Poppins;
font-size: 45px;
font-weight: 600;
line-height: 84px;
letter-spacing: 0px;
text-align: left;
color: #FAFAFA;

@media(max-width: 768px) {
    font-size: 30px;
    text-align: center;
    margin: 40px 0px 30px 0px;
}
`

export const BlogParagraph = styled.p`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
text-align: left;
color: #FAFAFA;
margin-top: -20px;

@media(max-width: 768px) {
    text-align: left;
    padding: 0px 30px;
}
`

export const BlogSocial = styled.div`
margin: 4px 0px 0px 50px;

a{
    margin-right: 20px;
}

@media(max-width: 768px) {
    margin-top: 20px;
}

`

export const BlogFooter = styled.h4`
display: flex;

time{
    color: #FAFAFA;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;    
    text-align: center;

    @media(max-width: 768px) {
        margin: 10px;
    }
}

span{
    position: relative;
    bottom: 5px;
    color: #FAFAFA;
    font-size: 20px;
    font-weight: 600;   
    text-align: center;
    margin: 0px 20px;

    @media(max-width: 768px) {
        display: none;
    }
}

@media(max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}
`

export const Facebook = styled.img`
height: 20px;
width: 20px;
border-radius: 0px;
`

export const Twitter = styled.img`
height: 17px;
width: 20.919998168945312px;
border-radius: 0px;
`

export const Share = styled.img`
height: 20px;
width: 18px;
border-radius: 0px;
`