import React from "react";
import { Btn } from "./Button.styled";

/* 
  Props

  bg = "black" || "white" || "gold" || "#00B16E" (green) || "#FF4633" (red)

*/
const Button = ({ bg, children, disabled, onClick }) => {
  return (
    <Btn bg={bg} disabled={disabled} onClick={() => onClick()}>
      {children}
    </Btn>
  );
};

export default Button;
