import React from 'react'
import support from "../../assets/svg/support.svg";
import { SupportContainer, SupportText, SupportActionButton} from './SupportButton.styled';

function SupportButton() {
  return (
    <SupportContainer target="_blank" rel='noreferrer' href='http://wa.me/+233509994874'>
        <SupportActionButton>
            <img src={support} alt="" />
            <SupportText className='support-text'>Support</SupportText>
        </SupportActionButton>
    </SupportContainer>
  )
}

export default SupportButton