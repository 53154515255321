import styled from "styled-components";

export const SupportText = styled.span`
  display: none;
  position: absolute;
  right: -0.1rem;
  top: -3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #ffffff;
  --transform-translate-y: -100%;
  color: #000000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  width: 4rem;
  border-radius: 0.375rem;

  &::after {
    content: "";
    position: absolute;
    left: 75%;
    --transform-translate-x: -75%;
    top: 100%;
    border-width: 6px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-top: 6px solid #fff;
  }
`;

export const SupportContainer = styled.a`
  position: relative;
  z-index: 2;

  &:hover ${SupportText} {
    display: flex;
  }
`;

export const SupportActionButton = styled.button`
  display: flex;
  position: fixed;
  right: 2rem;
  bottom: 2.5rem;
  background-color: #ffffff;
  font-size: 2.25rem;
  line-height: 2.5rem;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 9999px;
  outline: none;
  transition-duration: 300ms;
  border: none !important;

  :hover {
    cursor: pointer;
    background: #ffbb1c;
  }

  @media (max-width: 780px) {
    bottom: 6rem;
  }

  @media (max-width: 450px) {
    right: 1rem;
    bottom: 4.5rem;
  }
`;
