import { Header, Button, SideBar, Footer } from "../../components";
import { ReactComponent as Upvote } from "../../assets/svg/upvote.svg";
import { ReactComponent as BackHand } from "../../assets/svg/back_hand.svg";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/svg/twitter.svg";
import { ReactComponent as Whatsapp } from "../../assets/svg/whatsapp.svg";
import { ReactComponent as Share } from "../../assets/svg/share.svg";
import { Wrapper, HeadWrapper } from "../../pages/Home/Home.styled";
import SupportButton from "../../components/Support/SupportButton";
import {
  Heading,
  Details,
  DetailsImg,
  DetailsInfo,
  DetailsBtn,
  Social,
  Container,
} from "./ContestantsDetails.styled";
import React from "react";
import data from "../../User";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

export default function ContestantsDetails() {
  const { contestantId } = useParams();
  const navigate = useNavigate();
  const contestant = data.find((contestant) => contestant.id === contestantId);
  const { img, region, name, insta, age, bio } = contestant;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div>
      <SideBar />
      <HeadWrapper>
        <Header />
      </HeadWrapper>
      <Wrapper>
        <Container>
          <Heading>{name}</Heading>
          <Details>
            <DetailsImg src={img} alt={name} />

            <DetailsInfo>
              <h3>
                Region:<span> {region}</span>
              </h3>
              <h3>
                Age:<span> {age}</span>
              </h3>
              <h3>
                Instagram:<span> {insta}</span>
              </h3>
              <h3>
                Bio: <br />
                <span> {bio}</span>
              </h3>
              <DetailsBtn>
                {width < 425 ? (
                  <>
                    <Button
                      bg="gold"
                      onClick={() => navigate(`/vote/${contestant.id}`)}
                    >
                      <Upvote />
                      Vote
                    </Button>
                    <Button bg="white" onClick={() => navigate("/contests")}>
                      <BackHand />
                      Back to delegates
                    </Button>
                  </>
                ) : (
                  <>
                    <Button bg="white" onClick={() => navigate("/contests")}>
                      <BackHand />
                      Back to delegates
                    </Button>
                    <Button
                      bg="gold"
                      onClick={() => navigate(`/vote/${contestant.id}`)}
                    >
                      <Upvote />
                      Vote
                    </Button>
                  </>
                )}

                <Social>
                  <Link to="/link">
                    <Facebook />
                  </Link>
                  <Link to="/link">
                    <Twitter />
                  </Link>
                  <Link to="/link">
                    <Whatsapp />
                  </Link>
                  <Link to="/link">
                    <Share />
                  </Link>
                </Social>
              </DetailsBtn>
            </DetailsInfo>
          </Details>
        </Container>
      </Wrapper>
      <SupportButton />
      <Footer />
    </div>
  );
}
