import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;

  @media (max-width: 780px) {
    gap: 1.5rem;
  }
`;

export const Card = styled.div`
  position: relative;
  width: 19rem;
  @media (max-width: 780px) {
    width: 265px;
  }
`;

export const CardImg = styled.img`
  height: 23rem;
  border-radius: 0.5rem;
  width: 100%;
  object-fit: cover;
  @media (max-width: 780px) {
    height: 324px;
    justify-content: center;
  }
`;
export const Info = styled.img`
  border-radius: 10px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;

  @media (max-width: 780px) {
    right: 0.8rem;
  }
`;
export const Vote = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 780px) {
    padding: 0 0.5rem;
  }

  & h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fafafa;

    @media (max-width: 780px) {
      font-size: 12px;
    }
  }
`;
export const Statistics = styled.div`
  width: 56px;
  height: 56px;
  background: #ffbb1c;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  & svg path,
  svg g path {
    fill: black;
  }
  @media (max-width: 780px) {
    width: 46px;
    height: 46px;
  }
`;
export const Name = styled.p`
  position: absolute;
  background: #ffbb1c;
  padding: 0.8rem 1.5rem;
  color: #000000;
  font-size: 15.41px;
  font-weight: 500;
  border-radius: 3rem;
  left: 1rem;
  bottom: 5rem;

  @media (max-width: 780px) {
    bottom: 3.5rem;
  }
`;
