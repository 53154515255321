import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg";
import { ReactComponent as ContestsIcon } from "../../assets/svg/contest.svg";
import { ReactComponent as StatisticsIcon } from "../../assets/svg/statistics.svg";
import { ReactComponent as BlogIcon } from "../../assets/svg/blog.svg";
import { ReactComponent as AboutIcon } from "../../assets/svg/about.svg";
import logo from "../../assets/images/miss_emerald_logo.png";
import {
  SideBarWrapper,
  Logo,
  MenuItemWrapper,
  MenuItem,
  IconWrapper,
} from "./SideBar.styled";

/*
 **SideBar Props**
 page = "home" || "contests" || statistics || blog || about

*/

const SideBar = ({ page }) => {
  const navigate = useNavigate();
  return (
    <SideBarWrapper>
      <Logo onClick={() => navigate("/")} src={logo} alt="MEG" />
      <MenuItemWrapper>
        <MenuItem to="/home">
          <IconWrapper selected={page === "home"}>
            <HomeIcon />
          </IconWrapper>
          <p>Home</p>
        </MenuItem>
        <MenuItem to="/contests">
          <IconWrapper selected={page === "contests"}>
            <ContestsIcon />
          </IconWrapper>
          <p>Contests</p>
        </MenuItem>
        <MenuItem to="/statistics">
          <IconWrapper selected={page === "statistics"}>
            <StatisticsIcon width="24px" height="24px" />
          </IconWrapper>
          <p>Statistics</p>
        </MenuItem>
        <MenuItem to="/blog">
          <IconWrapper selected={page === "blog"}>
            <BlogIcon />
          </IconWrapper>
          <p>Blog</p>
        </MenuItem>
        <MenuItem to="/about">
          <IconWrapper selected={page === "about"}>
            <AboutIcon />
          </IconWrapper>
          <p>About</p>
        </MenuItem>
      </MenuItemWrapper>
    </SideBarWrapper>
  );
};

export default SideBar;
