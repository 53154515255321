import styled from "styled-components";

const Btn = styled.button`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  min-width: 5.5rem;
  width: max-content;
  background: ${(props) => (props.bg === "gold" ? "#FFBB1C" : props.bg)};
  border: ${(props) =>
    `2px solid ${props.bg === "gold" ? "#FFBB1C" : props.bg}`};
  color: ${(props) =>
    props.bg === "white" || props.bg === "gold" ? "black" : "white"};

  svg {
    margin-right: 8px;
  }

  &:hover {
    background: ${(props) => (props.bg === "black" ? "white" : "none")};
    border: ${(props) =>
      `2px solid ${
        props.bg === "gold"
          ? "#FFBB1C"
          : props.bg === "black"
          ? "white"
          : props.bg
      }`};
    color: ${(props) => (props.bg === "gold" ? "#FFBB1C" : props.bg)};
  }

  &:hover svg path:nth-child(1) {
    fill: ${(props) =>
      props.bg === "white" || props.bg === "black" ? props.bg : "none"};
  }

  &:hover svg path:nth-child(2) {
    stroke: #ffbb1c;
  }

  &:hover svg path:nth-child(3) {
    fill: #ffbb1c;
    stroke: #ffbb1c;
  }

  &:disabled {
    background: #d8d8d8;
    color: #818181;
    cursor: not-allowed;
    border: 2px solid #d8d8d8;
  }
`;

export { Btn };
