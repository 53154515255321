import { evicteds } from "../../User";
import {
  Card,
  Info,
  Name,
  Vote,
  Statistics,
} from "../../components/ContestantsCard/ContestantsCard.styled";
import { CardImgs } from "./Eviction.styled";
import info from "../../assets/svg/info.svg";
import statistics from "../../assets/svg/statistics.svg";
import { Wrapper } from "../../pages/Home/Home.styled";
import { Container } from "../../components/ContestantsCard/ContestantsCard.styled";

export function Evicted() {
  return (
    <Wrapper>
      <Container>
        {evicteds.map((evicted) => {
          return (
            <Card
              css={`
                position: relative;
              `}
              key={evicted.id}
            >
              <CardImgs src={evicted.picture} />
              <Info src={info} />
              <Name>{evicted.name}</Name>
              <Vote>
                <h4>{evicted.noVotes} votes</h4>
                <Statistics>
                  <img src={statistics} alt="" />
                </Statistics>
              </Vote>
            </Card>
          );
        })}
      </Container>
    </Wrapper>
  );
}
