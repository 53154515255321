import styled from "styled-components";

export const Header = styled.header`
  z-index: 30;
  background: #000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  @media (min-width: 868px) {
    background: transparent;
  }
`;

export const HeaderInner = styled.div`
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 72rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media (min-width: 1024px) {
    gap: 1.5rem;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  flex: 1 1 0%;
  width: 0;

  @media (min-width: 868px) {
    display: none;
  }
`;

export const Navbar = styled.nav`
  display: none;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  align-items: center;
  gap: 2rem;

  @media (min-width: 868px) {
    display: flex;
  }
`;

export const NavbarLinks = styled.a`
  color: #000000;
  transition-duration: 300ms;

  :hover {
    font-weight: 500;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    background-color: #000000;
    color: #ffffff;
    border-radius: 9999px;
  }
`;

export const NavbarBtnWrapper = styled.div`
  display: none;
  flex: 1 1 0%;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: 868px) {
    display: flex;
  }
`;

export const MenuIcon = styled.div`
  color: #ffffff;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MenuButton = styled.button`
  padding: 0.5rem;
  outline: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

// styles for mobile screen

export const MobileHeader = styled.header`
  display: flex;
  position: relative;
  z-index: 50;
  transition-duration: 300ms;

  @media (min-width: 868px) {
    display: none;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0.25;
`;

export const MobileNavbar = styled.nav`
  display: flex;
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #000000;
  flex-direction: column;
  width: 73.333333%;
  max-width: 24rem;
`;

export const NavbarHeader = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
`;

export const MobileLogo = styled.a`
  margin-right: auto;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  line-height: 1;
`;

export const MobileNavContainer = styled.div``;

export const MobileNavInner = styled.div``;

export const MobileNavList = styled.li`
  margin-bottom: 0.25rem;
  list-style-type: none;
`;

export const MobileNavLinks = styled.a`
  display: block;
  padding: 1rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  transition-duration: 300ms;

  :hover {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #ffffff;
    color: #000000;
    border-radius: 9999px;
    width: fit-content;
  }
`;

export const MobileFooter = styled.div`
  margin-top: auto;
`;

export const MobileFooterInner = styled.div`
  padding-top: 1.5rem;
`;

export const MobileCopyright = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #9ca3af;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
`;

export const MobilePrimarybtn = styled.a`
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 0.75rem;
  background-color: #ffffff;
  color: #000000;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  border-radius: 9999px;
  width: fit-content;
`;

export const MobileSecondarybtn = styled.a`
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 0.5rem;
  background-color: #ffbb1c;
  color: #000000;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  border-radius: 9999px;
  width: fit-content;
`;
