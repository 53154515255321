import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Upvote } from "../../assets/svg/upvote-contestant.svg";
import info from "../../assets/svg/info.svg";
import statistics from "../../assets/svg/statistics.svg";
import data from "../../User";
import {
  Card,
  CardImg,
  Info,
  Name,
  Statistics,
  Vote,
  Container,
} from "./ContestantsCard.styled";

export default function ContestantsCard({ isHomePage }) {
  return (
    <Container>
      {data.map((contestant) => {
        return (
          <Card key={contestant.votes}>
            <Link to={`/contestant/${contestant.id}`}>
              {
                // Todo: Create /contestant/id route
              }
              <CardImg src={contestant.img} />
              <Info src={info} />
              <Name>
                {isHomePage
                  ? contestant.region
                  : contestant.name?.startsWith("Obaa")
                  ? contestant.name.split(" ")[1]
                  : contestant.name.split(" ")[0]}
              </Name>
            </Link>
            <Vote to={isHomePage ? `/vote/${contestant.id}` : "/statistics"}>
              {
                // Todo: Create statistics route
              }
              <h4>
                {isHomePage
                  ? `${contestant.name}, ${contestant.age}`
                  : `${contestant.votes
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} votes`}
              </h4>
              {isHomePage ? (
                <Upvote />
              ) : (
                <Statistics>
                  <img src={statistics} alt="" />
                </Statistics>
              )}
            </Vote>
          </Card>
        );
      })}
    </Container>
  );
}
