import React from 'react'
import { ActivityTableContainer, ActivityTableData, ActivityTableInner, ActivityTableTable, ActivityTableTableTitle, ActivityTableWrapper } from './ActivityTable.styled'

const notificationsData = [
    {
        id:"1",
        dateAdded:"8 mins ago",
        name:"Grace Naa Ashorkor"
    },
    {
        id:"2",
        dateAdded:"10 mins ago",
        name:"Millicent Akosua"
    },
    {
        id:"3",
        dateAdded:"12 mins ago",
        name:"Obaa Yaa"
    },
    {
        id:"4",
        dateAdded:"30 mins ago",
        name:"Aba Mensah"
    },
    {
        id:"5",
        dateAdded:"33 mins ago",
        name:"Mavis Osei"
    },
    {
        id:"6",
        dateAdded:"38 mins ago",
        name:"Shelly Shay"
    },
    {
        id:"7",
        dateAdded:"40 mins ago",
        name:"Mavis Osei"
    },
    {
        id:"8",
        dateAdded:"42 mins ago",
        name:"Shelly Shay"
    },
    {
        id:"9",
        dateAdded:"45 mins ago",
        name:"Shelly Shay"
    },
    {
        id:"10",
        dateAdded:"48 mins ago",
        name:"Mavis Osei"
    },
]

function ActivityTable() {
  return (
    <ActivityTableContainer>
        <ActivityTableWrapper>
            <ActivityTableInner>
                <ActivityTableTable>
                    <thead>
                            <tr>
                                <ActivityTableTableTitle>Recent Activities</ActivityTableTableTitle>

                                <ActivityTableTableTitle></ActivityTableTableTitle>
                            </tr>
                    </thead>

                    <tbody>
                        {notificationsData.map((contestant)=> (
                            <tr key={contestant.id}>
                                <ActivityTableData>
                                    Someone voted for {contestant.name}
                                </ActivityTableData>

                                <ActivityTableData>
                                    {contestant.dateAdded}
                                </ActivityTableData>
                            </tr>
                        ))}
                    </tbody>
                </ActivityTableTable>
            </ActivityTableInner>
        </ActivityTableWrapper>
    </ActivityTableContainer>
  )
}

export default ActivityTable
