import styled from "styled-components";
export const Heading = styled.h1`
  font-weight: 600;
  font-size: 54px;
  color: white;
  @media (max-width: 780px) {
    font-weight: 400;
    font-size: 44px;
  }
`;

export const Details = styled.div`
  display: flex;
  column-gap: 20px;
  color: white;
  margin: 0 auto;
  align-items: center;
  @media (max-width: 780px) {
    flex-wrap: wrap;
  }
`;
export const DetailsInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.2rem 1rem;
  span {
    font-weight: 400;
    font-size: 20px;
  }
  @media (max-width: 780px) {
    margin: 0.2rem 0rem;
    font-weight: 300;
    font-size: 16px;
    span {
      font-weight: 300;
      font-size: 16px;
    }
  }
`;

export const DetailsImg = styled.img`
  border-radius: 0.8rem;
  width: 450px;
  height: 450px;
  object-fit: cover;
  @media (max-width: 780px) {
    width: 255px;
    height: 330px;
  }
`;

export const DetailsBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  @media (max-width: 780px) {
    gap: 0.8rem;
    flex-wrap: wrap;
  }

  @media (max-width: 608px) {
    justify-content: center;
  }
`;

export const Social = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 780px) {
    gap: 0.8rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
