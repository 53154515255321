import React from "react";
import { Button } from "../../components";
import {
  BlogsCat,
  BlogsGrid,
  BlogsCard,
  BlogsImage,
  BlogsHeading,
  BlogsSubheading,
} from "./Blogs.styled";
import { blogsData } from "../../User";

export default function Blogs() {
  return (
    <div>
      <BlogsCat>
        <Button bg="black">All</Button>
        <Button bg="black">Instagram</Button>
        <Button bg="black">Facebook</Button>
        <Button bg="black">Twitter</Button>
        <Button bg="black">Snapchat</Button>
        <Button bg="black">TikTok</Button>
      </BlogsCat>

      <BlogsGrid>
        {blogsData.map((blog) => {
          return (
            <div key={blog.id}>
              <BlogsCard>
                <BlogsImage src={blog.img} />
              </BlogsCard>

              <BlogsHeading>{blog.title}</BlogsHeading>
              <BlogsSubheading>{blog.info}</BlogsSubheading>
            </div>
          );
        })}
        ;
      </BlogsGrid>
    </div>
  );
}
