import styled from "styled-components";

export const Nav = styled.nav`
  margin-top: 5rem;
  display: flex;
  column-gap: 43px;

  a {
    color: white;
    font-size: 25px;
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 0.25rem;
    cursor:pointer;
    }

    a:hover,
    a.selected {
      padding-bottom: 0.2rem;
      border-bottom: 0.1rem solid #fffb1c;
    }
  

  @media (max-width: 780px) {
    margin-top: 44px;
    column-gap: 35px;
    a {
      font-size: 20px;
    }
  }
`;
