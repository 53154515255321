import React from "react";
import { Footer, SideBar } from "../../components";
import {
  AboutDescription,
  AboutImage,
  Container,
  ImageContainer,
  MemberDescription,
  MemberImage,
  MemberImageContainer,
  MemberName,
  TeamContainer,
  TeamGrid,
  TeamSection,
  Title,
  Wrapper,
} from "./About.styled";
import aboutImg from "../../assets/images/aboutImage.jpeg";
import member1 from "../../assets/images/about1.jpeg";
import member2 from "../../assets/images/about2.jpeg";
import SupportButton from "../../components/Support/SupportButton";

function About() {
  return (
    <div>
      <SideBar page="about" />

      <Wrapper>
        <Container>
          <Title>About Us</Title>
          <ImageContainer>
            <AboutImage src={aboutImg} alt="" />
          </ImageContainer>
          <AboutDescription>
            The maiden Miss Emerald Ghana (MEG) is a beauty pageant which
            educates Ghanaians and the global community about an ideal woman and
            her role as a citizen. The program is organized by {"Mate’s"} team
            and its sponsors and partners. The aim of the pageant program is to
            promote a better Ghana through beauty performances.
            <br />
            <br />
            MEG allows viewers to vote for their favorite contestant to be
            crowned Miss Emerald Ghana. Each week, the contestants are given
            tasks to perform which ranges from performances to organizing
            events. This is then assessed by a panel of judges who have a
            certain degree of power in selecting successful candidates and
            eliminating others.
            <br />
            <br />
            For Sponsorship and Partnership contact: 057 475 2451 / 050579 5208
          </AboutDescription>
        </Container>
      </Wrapper>

      <TeamSection>
        <TeamContainer>
          <Title>The Team</Title>

          <TeamGrid>
            <div>
              <MemberImageContainer>
                <MemberImage src={member1} alt="" />
              </MemberImageContainer>

              <MemberName>Nyaaba A. Diana</MemberName>
              <MemberDescription>
                Nurse <br />
                Former SRC General Secretary, St. Karol School of Nursing UNSAG
                Parliament Clark. UNSAG {"women’s"} commissioner hopeful.
              </MemberDescription>
            </div>

            <div>
              <MemberImageContainer>
                <MemberImage src={member2} alt="" />
              </MemberImageContainer>

              <MemberName>Miss Matey D. Millicent</MemberName>
              <MemberDescription>
                Founder of MateMed & Millimate trends. SRC Vice President of
                Baldwin University College. Face of National Health Student
                Association of Ghana 2020, (NAHSAG) winner.
              </MemberDescription>
            </div>
          </TeamGrid>
        </TeamContainer>
      </TeamSection>
      <SupportButton />

      {/* Added a full attribute to the footer component to display the full-content footer */}
      <Footer full />
    </div>
  );
}

export default About;
