const data = [
  {
    id: "1",
    img: "/images/img4.jpg",
    name: "Grace Naa Ashorkor",
    age: 23,
    region: "Greater Accra",
    votes: 1234567,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "2",
    img: "/images/img14.jpg",
    name: "Ama Mensah",
    age: 22,
    region: "Ashanti",
    votes: 234567,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "3",
    img: "/images/img1.jpg",
    name: "Shelly Shay",
    age: 24,
    region: "Ahafo",
    votes: 34567,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "4",
    img: "/images/img12.jpg",
    name: "Queen Quanoo",
    age: 22,
    region: "Central",
    votes: 4567,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "5",
    img: "/images/img10.jpg",
    name: "Obaapa Celestine",
    age: 21,
    region: "Savannah",
    votes: 4566,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "6",
    img: "/images/img8.jpg",
    name: "Mavis Osei",
    age: 23,
    region: "Eastern",
    votes: 4565,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "7",
    img: "/images/img2.jpg",
    name: "Esi Manu Osei",
    age: 21,
    region: "Bono East",
    votes: 567,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "8",
    img: "/images/img15.jpg",
    name: "Esther Atia",
    age: 25,
    region: "Upper East",
    votes: 565,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "9",
    img: "/images/img3.jpg",
    name: "Millicent Akosua",
    age: 21,
    region: "Bono",
    votes: 563,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "10",
    img: "/images/img13.jpg",
    name: "Obaa Yaa",
    age: 22,
    region: "Oti",
    votes: 167,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "11",
    img: "/images/img11.jpg",
    name: "Mavis Osei",
    age: 23,
    region: "North East",
    votes: 160,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
  {
    id: "12",
    img: "/images/img9.jpg",
    name: "Dorcas Akpene",
    age: 23,
    region: "Volta",
    votes: 100,
    insta: "@maveintrax",
    bio: "Mavis is a student of life who believes in togetherness as the key to bridge the gap between people and societies. According to her, the best way to get inspired by watching relatable examples find success. Therefore, she became the first in her family to graduate eith a Master of Science degree in a third language.",
  },
];
export default data;

export const blogsData = [
  {
    id: "1",
    img: "/images/blog4.png",
    title: "The Militimate Journal",
    info: "Next Miss Emerald Ghana pageant to be broadcastfrom Accra, Ghana",
  },
  {
    id: "2",
    img: "/images/blog1.png",
    title: "JoeOkat Tech",
    info: "How Mavis is using her platform to fight Stigmas surrounding menstrual equity",
  },
  {
    id: "3",
    img: "/images/blog2.png",
    title: "Raha Blogspot",
    info: "Delegates to assemble at Nationsl Theater for their auditions",
  },
  {
    id: "4",
    img: "/images/blog3.png",
    title: "Advertising Week",
    info: "Setting the records straight with The Miss Emerald Ghana Organisation",
  },
  {
    id: "5",
    img: "/images/blog2.png",
    title: "Raha Blogspot",
    info: "Delegates to assemble at Nationsl Theater for their auditions",
  },
  {
    id: "6",
    img: "/images/blog3.png",
    title: "Advertising Week",
    info: "Setting the records straight with The Miss Emerald Ghana Organisation",
  },
  {
    id: "7",
    img: "/images/blog4.png",
    title: "The Militimate Journal",
    info: "Next Miss Emerald Ghana pageant to be broadcastfrom Accra, Ghana",
  },
  {
    id: "8",
    img: "/images/blog1.png",
    title: "JoeOkat Tech",
    info: "How Mavis is using her platform to fight Stigmas surrounding menstrual equity",
  },
  {
    id: "9",
    img: "/images/blog4.png",
    title: "The Militimate Journal",
    info: "Next Miss Emerald Ghana pageant to be broadcastfrom Accra, Ghana",
  },
  {
    id: "10",
    img: "/images/blog1.png",
    title: "JoeOkat Tech",
    info: "How Mavis is using her platform to fight Stigmas surrounding menstrual equity",
  },
  {
    id: "11",
    img: "/images/blog2.png",
    title: "Raha Blogspot",
    info: "Delegates to assemble at Nationsl Theater for their auditions",
  },
  {
    id: "12",
    img: "/images/blog3.png",
    title: "Advertising Week",
    info: "Setting the records straight with The Miss Emerald Ghana Organisation",
  },
  {
    id: "13",
    img: "/images/blog2.png",
    title: "Raha Blogspot",
    info: "Delegates to assemble at Nationsl Theater for their auditions",
  },
  {
    id: "14",
    img: "/images/blog3.png",
    title: "Advertising Week",
    info: "Setting the records straight with The Miss Emerald Ghana Organisation",
  },
  {
    id: "15",
    img: "/images/blog4.png",
    title: "The Militimate Journal",
    info: "Next Miss Emerald Ghana pageant to be broadcastfrom Accra, Ghana",
  },
  {
    id: "16",
    img: "/images/blog1.png",
    title: "JoeOkat Tech",
    info: "How Mavis is using her platform to fight Stigmas surrounding menstrual equity",
  },
  {
    id: "17",
    img: "/images/blog4.png",
    title: "The Militimate Journal",
    info: "Next Miss Emerald Ghana pageant to be broadcastfrom Accra, Ghana",
  },
  {
    id: "18",
    img: "/images/blog1.png",
    title: "JoeOkat Tech",
    info: "How Mavis is using her platform to fight Stigmas surrounding menstrual equity",
  },
  {
    id: "19",
    img: "/images/blog2.png",
    title: "Raha Blogspot",
    info: "Delegates to assemble at Nationsl Theater for their auditions",
  },
  {
    id: "20",
    img: "/images/blog3.png",
    title: "Advertising Week",
    info: "Setting the records straight with The Miss Emerald Ghana Organisation",
  },
];

export const evicteds = [
  {
    id: "1",
    name: "Esi",
    picture: "/images/img13.jpg",
    noVotes: 67,
  },
  {
    id: "2",
    name: "Atia",
    picture: "/images/img14.jpg",
    noVotes: 57,
  },
  {
    id: "3",
    name: "Sellina",
    picture: "/images/img15.jpg",
    noVotes: 47,
  },
  {
    id: "4",
    name: "Patience",
    picture: "/images/img0.jpg",
    noVotes: 17,
  },
];

export const counts = [
  {
    id: 1,
    amt: 1,
  },
  { id: 2, amt: 3 },

  { id: 3, amt: 5 },
  {
    id: 4,
    amt: 10,
  },
  {
    id: 5,
    amt: 20
  },
  {
    id: 6,
    amt: 50,
  },
  {
    id: 7,
    amt: 70,
  },
  {
    id: 8,
    amt: 100,
  },
  {
    id: 9,
    amt: 500,
  },
  {
    id: 10,
    amt: 1000,
  },
];
