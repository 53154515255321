import styled from "styled-components";
export const Modal = styled.div`
  top: 50%;
  left: 50%;
  width: 250px;
  height: 275px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 1000;
  padding: 20px 30px;
  position: fixed;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #101010;

  p {
    font-size: 20px;
    font-weight: 600;

    span {
      text-transform: uppercase;
    }
  }



  form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      font-size: 32px;
      font-weight: 600;
     margin-right:40px;
    }

    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: 1px solid black;
      margin-top: 10px;
    }
  }
  button {
    border: 2px solid black;
    height: 50%;
    width: auto;
    margin: 0 auto;

    &:hover {
      background: black;
      color: #ffffff;
      border: 2px solid #ffffff;
    }
  }
`;

export const Close = styled.button`
  background: red;
  border:2px solid red;
  position: relative;
  height: 20%;
  width: 50%;
  top: -55px;
  left:210px;
  border-radius: 50%;
  padding:2px;
`;
export const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;


`;

export const Pay = styled.div`
  color: #101010;
`;

export const ModalSuccess = styled.div`
display:flex;
flex-direction:column;
gap:2rem;
justify-content:center;
align-items:center;

button{
  height:20%;
  width:50%;
  padding:5px;
  border:2px solid green;
}`
