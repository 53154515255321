import React from "react";
import { Main,  MyContent,
  StyledHeader, } from "./Eviction.styled";
import { Wrapper } from "../../pages/Home/Home.styled";

function NoEvictions() {
  return (
    <Wrapper>
      <Main>
        <MyContent>
          <StyledHeader>No Evictions Yet</StyledHeader>
          <StyledHeader variant="outline">here
          </StyledHeader>
          <StyledHeader variant="outline">
            Go to contestants and vote now! before voting time is up
          </StyledHeader>
        </MyContent>
      </Main>
    </Wrapper>
  );
}

export default NoEvictions;
