import React from "react";
import { Wrapper, HeadWrapper } from "../Home/Home.styled";
import Blogs from "../../HOC/Blogs/Blogs";
import {
  AdsBanner,
  SideBar,
  Footer,
  BlogHeader,
  BlogSearch,
  BlogPgNav,
} from "../../components";
import SupportButton from "../../components/Support/SupportButton";

export default function Blog() {
  return (
    <div>
      <SideBar page="blog" />
      <HeadWrapper>
        <BlogSearch />
      </HeadWrapper>
      <AdsBanner />
      <Wrapper>
        <BlogHeader />
        <Blogs />
        <BlogPgNav />
      </Wrapper>
      <SupportButton />
      <Footer full={true} />
    </div>
  );
}
