import styled from "styled-components";

export const ActivityTableContainer = styled.div`
    max-width: 52rem; 
`;

export const ActivityTableWrapper = styled.div`
    overflow-x: auto; 
    padding-top: 1rem;
    padding-bottom: 1rem; 
    padding-right: 1rem;  

    @media (min-width: 640px) { 
        padding-right: 2rem;
    }
`;

export const ActivityTableInner = styled.div`
    display: inline-block; 
    overflow: hidden; 
    min-width: 100%; 
`;

export const ActivityTableTable = styled.table`
    line-height: 1.5; 
    min-width: 100%; 
    border-spacing: 0px;
`;

export const ActivityTableTableTitle = styled.th`
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; 
    padding-left: 1.25rem;
    padding-right: 1.25rem; 
    color: #ffffff; 
    font-size: 1rem;
    line-height: 1.5rem; 
    font-weight: 400; 
    text-align: left; 
    border-bottom: 2px solid #FFCE3D; 

    @media (min-width: 768px) {
        padding-left: 0.75rem;
        padding-right: 0.75rem; 
        font-size: 1.125rem;
        line-height: 1.75rem;  
    }
`;

export const ActivityTableData = styled.td`
    padding-top: 1rem;
    padding-bottom: 1rem; 
    padding-left: 1rem;
    padding-right: 1rem; 
    font-size: 1rem;
    line-height: 1.5rem; 
    border-bottom: 1px solid #FFCE3D; 
`;

export const ActivityTableStringData = styled.p`
    color: #F3F4F6; 
`;
