import React, { useState } from "react";
import { HeadWrapper, Wrapper } from "./Home.styled";
import {
  SideBar,
  AdsBanner,
  ContestantsCard,
  Footer,
  Header,
} from "../../components";
import data from "../../User";
import SupportButton from "../../components/Support/SupportButton";

const Home = () => {
  const [contestants, setContestants] = useState(data);
  const searchContestants = (keyword) => {
    if (!keyword) {
      setContestants(data);
      return;
    }
    keyword = keyword.toLocaleLowerCase();
    setContestants(
      data.filter(
        (c) =>
          c.name.toLocaleLowerCase().includes(keyword) ||
          c.region.toLocaleLowerCase().includes(keyword)
      )
    );
  };

  return (
    <div>
      <SideBar page="home" />
      <HeadWrapper>
        <Header onSearch={searchContestants} />
      </HeadWrapper>
      <AdsBanner />
      <Wrapper>
        <ContestantsCard data={contestants} isHomePage={true} />
        <SupportButton />
      </Wrapper>
      <Footer />
    </div>
  );
};

export default Home;
