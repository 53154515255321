import React from "react";
import Youtube from "../../assets/svg/youtube_large.svg";
import Button from "../Button/Button";
import { MHeader, MyContent, MyHeader, MyVid } from "./BlogSearch.styled";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
const BlogSearch = () => {
  const navigate = useNavigate();
  return (
    <div>
      <MHeader>
        <Header />
        <Button bg="gold" onClick={() => navigate("/home")}>
          Vote
        </Button>
      </MHeader>
      <MyContent>
        <MyHeader>Stream Meg</MyHeader>
        <MyVid>
          <img src={Youtube} alt="Youtube icon" />
        </MyVid>
      </MyContent>
    </div>
  );
};

export default BlogSearch;
