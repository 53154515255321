import styled from "styled-components";

export const VoteHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  justify-content: space-between;
  margin: 2rem 8rem;
  @media (max-width: 780px) {
    margin: 1.5rem;
  }

  button {
    background: black;
    color: #ffffff;
    border: 2px solid #ffffff;
    height: 50%;

    &:hover {
      backgound: none;
      color: none;
      border: 1px solid #ffffff;
    }
  }
  svg path {
    fill: #ffffff;
  }
`;
export const Token = styled.img`
  border: 2px solid #ffbb1c;
  border-radius: 8px;
  padding: 1rem;
  background: #ffc665;

  &:hover {
    background: #ffce3d;
  }

  @media (max-width: 425px) {
    width: 3rem;
    height: 3rem;
  }
`;
export const VoteCard = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  align-items: center;
`;

export const VotesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const VoteNo = styled.h5`
  font-size: 20px;
  font-weight: 500;
  margin: 0.2rem 0;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

export const Price = styled.h5`
  font-size: 20px;
  font-weight: 500;
  margin: 0.2rem 0;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;
