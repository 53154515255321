import React from "react";
import { Nav } from "./ContestsNav.styled";

export default function ContestsNav(props) {
  return (
    <Nav>
      <a
        className={props.selected === "contests" ? "selected" : ""}
        onClick={props.onContest}
      >
        Contestants
      </a>
      <a
        className={props.selected === "evicted" ? "selected" : ""}
        onClick={props.onEvict}
      >
        Evicted
      </a>
    </Nav>
  );
}
