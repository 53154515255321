import { Modal, Pay, Btn, ModalSuccess } from "./PaymentModal.styled";
import PayForm from "./PayForm";
import { useState } from "react";
import { Button } from "../../components";
import { ReactComponent as Success } from "../../assets/svg/success.svg";

export default function PaymentModal({ setModal, modal, name, amt }) {
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [pay, setPay] = useState(false);

  return (
    <div>
      {modal && (
        <Modal>
          <Pay>
            <h1> Confirm Vote</h1>
            <p>
              Confirm {amt} votes for Delegate:<span>{name}</span> for {amt} GHS
              ?
            </p>
            <Btn>
              <Button bg="white" onClick={() => setModal(!modal)}>
                Cancel
              </Button>
              <Button bg="black" onClick={() => setPay(!pay)}>
                Confirm
              </Button>
            </Btn>
          </Pay>
        </Modal>
      )}
      {pay && (
        <Modal>
          <PayForm
            setPay={setPay}
            modal={modal}
            amt={amt}
            name={name}
            data={data}
            success={success}
            setData={setData}
            setSuccess={setSuccess}
          />
        </Modal>
      )}

      {success && (
        <Modal>
          <ModalSuccess>
            <h1>Successful</h1>
            <Success />
            <Button onClick={() => setSuccess(!modal)} bg="green">
              Done
            </Button>
          </ModalSuccess>
        </Modal>
      )}
    </div>
  );
}
