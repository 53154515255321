import styled from "styled-components";
import Background from "../../assets/images/img0.jpg";

export const MHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 425px) {
    button {
      display: none;
    }
  }
`;

export const MyContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`;
export const MyHeader = styled.h1`
  text-align: center;
  font-family: Poppins;
  font-size: 56px;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: 0px;
  color: #fafafa;
`;
export const MyVid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 720px;
  width: 100%;
  border-radius: 8px;
  background: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;

  @media (max-width: 780px) {
    width: 400px;
    height: 300px;
  }
  @media (max-width: 425px) {
    width: 300px;
    height: 200px;
  }
`;
