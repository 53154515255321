import React from "react";
import { useState } from "react";
import { AdsBanner, Footer, Header, SideBar, NavBar } from "../../components";
import LeaderBoard from "../../components/LeaderBoard/LeaderBoard";
import SupportButton from "../../components/Support/SupportButton";
import { HeadWrapper } from "../Home/Home.styled";
import {
  Content,
  DataLabel,
  DataLabelsWrapper,
  LabelColor,
} from "./Statistics.styled";
import data from "../../User";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ActivityTable from "../../components/ActivityTable/ActivityTable";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Leaderboard = () => {
  return <LeaderBoard />;
};

const Infographics = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [];

  const datasets = [
    {
      data: [],
      backgroundColor: [],
    },
  ];

  data.forEach((item) => {
    datasets[0].data.push(item.votes);

    labels.push(
      item.name.startsWith("Obaa")
        ? item.name.split(" ")[1]
        : item.name.split(" ")[0]
    );

    datasets[0].backgroundColor.push(
      `#${Math.floor(Math.random() * 16777215).toString(16)}`
    );
  });

  const dataSet = { labels, datasets };

  return (
    <>
      <Bar options={options} data={dataSet} />
      <DataLabelsWrapper>
        {labels.map((label, idx) => (
          <DataLabel key={idx}>
            <LabelColor color={datasets[0].backgroundColor[idx]} />
            <p>{label}</p>
          </DataLabel>
        ))}
      </DataLabelsWrapper>
    </>
  );
};

const Activities = () => {
  return <ActivityTable />;
};

const Statistics = () => {
  const [selected, setSelected] = useState("Leaderboard");
  const switchPages = (event) => {
    setSelected(event.target.textContent);
  };
  return (
    <div>
      <SideBar page="statistics" />
      <HeadWrapper>
        <Header />
        <NavBar>
          <h2
            onClick={switchPages}
            className={selected === "Leaderboard" ? "selected" : null}
          >
            Leaderboard
          </h2>
          <h2
            onClick={switchPages}
            className={selected === "Infographics" ? "selected" : null}
          >
            Infographics
          </h2>
          <h2
            onClick={switchPages}
            className={selected === "Activities" ? "selected" : null}
          >
            Activities
          </h2>
        </NavBar>
        <Content>
          {selected === "Activities" ? (
            <Activities />
          ) : selected === "Infographics" ? (
            <Infographics />
          ) : (
            <Leaderboard />
          )}
        </Content>
      </HeadWrapper>
      <AdsBanner />
      <SupportButton />
      <Footer />
    </div>
  );
};

export default Statistics;
