import React from "react";
import {
  LandingPgHeading,
  TextSection,
  Button,
  Footer,
} from "../../components";
import {
  Image,
  RequirementsBox,
  RequirementsBoxInner,
  RequirementsContainer,
  RequirementsFlex,
  RequirementsImage,
  RequirementsText,
  RequirementWrapper,
  SectionWrapper,
  SponsorsWrapper,
  SponsporImage,
} from "./Landing.styled";
import logo from "../../assets/images/MEG_Logo_Black.png";
import image from "../../assets/images/MEG_2023_img.jpg";
import sponsor_unreal from "../../assets/images/sponsor_unreal.png";
import contest from "../../assets/svg/contest.svg";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import MenuBar from "../../components/MenuBar/MenuBar";

const Landing = () => {
  const navigate = useNavigate();
  return (
    <div
      /**Todo: White rectangles in background */
      css={`
        background: #ffce3d;
        height: 100%;
        width: 100%;
        gap: 4rem;
        display: flex;
        flex-direction: column;
      `}
    >
      <MenuBar />
      <SectionWrapper>
        <Image src={logo} alt="Miss Emerald Ghana" />
        <TextSection>
          <p>
            The Miss Emerald Ghana (<span>MEG</span>) contest promotes Ghanaian
            pride and patriotism with the belief in each contestant that she is
            capable of achieving the unthinkable. The pageant inspires girls to
            live their dreams, become successful, believe in themselves and
            strive endlessly.
          </p>
          <p>
            MEG's first edition is happening in February 2023, starting with
            auditions on the 18th of February 2023.
          </p>
          <Button
            bg="black"
            onClick={() =>
              /** Todo: Change this link */
              // window.open("https://forms.gle/RRkf2P4NJsHSkEfm6")
              navigate("/about")
            }
          >
            Become a partner
          </Button>
        </TextSection>
      </SectionWrapper>
      <SectionWrapper reverse={true}>
        <TextSection>
          <LandingPgHeading text="MEG 2023" />
          <p>
            Are you a young lady between the age of 18 to 25 years? Are you
            fluent in your local dialect and English? Do you need an
            Ambassadorial deal to promote your brand? Kindly fill out the forms
            or contact the numbers below to join Miss Emerald Ghana 2023
          </p>
          <Button
            bg="black"
            onClick={() => window.open("https://forms.gle/RRkf2P4NJsHSkEfm6")}
          >
            Become a delegate
          </Button>
        </TextSection>
        <Image
          src={image}
          css={`
            border-radius: 8px;
          `}
          alt="Miss Emerald Ghana 2023"
        />
      </SectionWrapper>
      <SectionWrapper col={true}>
        <LandingPgHeading text="Requirements" />
        <RequirementWrapper>
          <RequirementsContainer>
            <RequirementsFlex>
              <div>
                <RequirementsBox theme={"white"}>
                  <RequirementsBoxInner>
                    <RequirementsImage>
                      <img src={contest} alt="crown icon" />
                    </RequirementsImage>
                    <RequirementsText>Must be a Ghanaian</RequirementsText>
                  </RequirementsBoxInner>
                </RequirementsBox>
              </div>

              <div>
                <RequirementsBox theme={"black"}>
                  <RequirementsBoxInner>
                    <RequirementsImage>
                      <img src={contest} alt="crown icon" />
                    </RequirementsImage>
                    <RequirementsText>
                      Must be fluent in both local and English dialect
                    </RequirementsText>
                  </RequirementsBoxInner>
                </RequirementsBox>
              </div>

              <div>
                <RequirementsBox theme={"white"}>
                  <RequirementsBoxInner>
                    <RequirementsImage>
                      <img src={contest} alt="crown icon" />
                    </RequirementsImage>
                    <RequirementsText>
                      Must be between the age of 18 and 25
                    </RequirementsText>
                  </RequirementsBoxInner>
                </RequirementsBox>
              </div>
            </RequirementsFlex>
          </RequirementsContainer>
        </RequirementWrapper>
      </SectionWrapper>
      <SectionWrapper id="sponsors" col={true}>
        <LandingPgHeading text="Sponsors & Partners" />
        <SponsorsWrapper>
          <SponsporImage src={sponsor_unreal} alt="Unreal" />
          <SponsporImage src={sponsor_unreal} alt="Unreal" />
          <SponsporImage src={sponsor_unreal} alt="Unreal" />
          <SponsporImage src={sponsor_unreal} alt="Unreal" />
        </SponsorsWrapper>
      </SectionWrapper>
      <Footer full={true} />
    </div>
  );
};

export default Landing;
