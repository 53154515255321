import styled from "styled-components";
import { Link } from "react-router-dom";

export const SideBarWrapper = styled.div`
  background-color: #000000;
  color: white;
  padding: 1rem;
  height: 30rem;
  display: flex;
  width: 3rem;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  text-align: center;

  @media (max-width: 780px) {
    width: 100%;
    top: unset;
    bottom: 0;
    height: 3rem;
    flex-direction: row;
    z-index: 3;
  }

  @media (max-width: 450px) {
    padding: 0.5rem;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  @media (max-width: 450px) {
    display: none;
  }
`;

export const MenuItem = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: unset;
  color: unset;
  height: fit-content;
  align-items: center;
  margin: 1rem;

  p {
    margin: 0;
    font-size: 0.7rem;
  }

  :hover div {
    background-color: #ffbb1c;
  }

  :hover svg g path,
  :hover svg path {
    fill: black;
  }

  @media (max-width: 425px) {
    margin: 0.3rem;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;

  @media (max-width: 780px) {
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }

  @media (max-width: 425px) {
    width: 100%;
    margin: unset;
    justify-content: space-evenly;
  }
`;

export const IconWrapper = styled.div`
  border-radius: 0.8rem;
  width: 2.8rem;
  height: 1.6rem;
  background-color: ${(props) => (props.selected ? "#ffbb1c" : "black")};
  display: flex;
  align-item: center;
  justify-content: center;
  svg path,
  svg g path {
    fill: ${(props) => (props.selected ? "black" : "white")};
  }
`;
