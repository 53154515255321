import styled from "styled-components";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0;
  ${(props) => (props.col ? { gap: "2rem" } : null)}

  @media (max-width: 685px) {
    align-items: center;
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
    gap: 1.5rem;
  }
`;

const Image = styled.img`
  width: 45%;
  max-width: 29rem;
  object-fit: contain;
  color: black;
  @media (max-width: 685px) {
    width: 70%;
  }
`;

const SponsorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  width: 100%;
  gap: 1rem;
  margin: 2rem 0;
`;

const SponsporImage = styled.img`
  width: 8rem;
  height: 8rem;
  object-fit: contain;

  @media (max-width: 780px) {
    width: 6rem;
    height: 6rem;
  }
`;

const RequirementWrapper = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
  text-align: center !important; 

  @media (min-width: 640px) { 
    padding-top: 1rem;
    padding-bottom: 1rem; 
  }

  @media (min-width: 1024px) { 
    padding-top: 2rem;
    padding-bottom: 2rem; 
  }
`;

const RequirementsContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem; 
 
  margin-left: auto;
  margin-right: auto; 
  text-align: center; 
  max-width: 768px;
  
  @media (min-width: 768px) { 
    padding-left: 2rem;
    padding-right: 2rem; 
  }
`;

const RequirementsFlex = styled.div`
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center; 
  column-gap: 4rem; 
  row-gap: 1rem; 

  @media (min-width: 768px) {
    row-gap: 2rem;  
  }
`;

const RequirementsBox = styled.div`
  padding-top: 0.1rem;
  padding-bottom: 0.1rem; 
  padding-left: 0.75rem;
  padding-right: 0.75rem; 
  border-radius: 0.5rem; 
  ${(props) => (props.theme === "white" ? { backgroundColor: "#ffffff", color: "#000000" } : { backgroundColor: "#000000", color: "#ffffff" })}
`;

const RequirementsBoxInner = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center; 
  column-gap: 0.5rem; 

  @media (min-width: 768px) { 
    column-gap: 0.75rem; 
  }
`;

const RequirementsImage = styled.div`
  padding-top: 0.2rem;
  padding-bottom: 0rem; 
  padding-left: 0.25rem;
  padding-right: 0.25rem; 
  text-align: center; 
  border-radius: 9999px; 
  background: #FFCE3D;
`;

const RequirementsText = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem; 
  font-weight: 500; 
  text-align: center;  
`;

export { SectionWrapper, Image, SponsorsWrapper, SponsporImage, RequirementWrapper, RequirementsContainer, RequirementsFlex, RequirementsBox, RequirementsBoxInner, RequirementsImage, RequirementsText };
