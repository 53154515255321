import styled from "styled-components";

export const StyledHeader = styled.form`
  display: flex;
  position: relative;

  input {
    width: 345px;
    height: 56px;
    border: 1px solid #fafafa;
    border-radius: 50px;
    padding: 25px;
    background: #010101;
    color: white;
  }

  img {
    position: absolute;
    top: 1rem;
    left: 300px;
  }

  @media (max-width: 780px) {
    input {
      width: 245px;
      height: 46px;
    }
    img {
      position: absolute;
      left: 200px;
      top: 0.9rem;
    }
  }
`;
