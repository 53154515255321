import styled from "styled-components";

export const MyNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top:5rem;

`;

export const SubLink = styled.a`
  padding: 7px 24px;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  :hover {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
    background: #ffbb1c;
    border-radius: 50px;
    border: 1px solid #ffbb1c;
    transition: background 0.5s;
  }
  @media (max-width: 780px) {
    padding:7px 14px;
  }
`;
export const MyVector = styled.div`
  height: 17.250862121582045px;
  width: 17.254713058471694px;
  padding: 7px 24px;
  border-radius: 0px;
  cursor:pointer;
`;
