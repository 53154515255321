import styled from "styled-components";

export const Wrapper = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; 
    background: #111;

    @media (min-width: 640px) { 
        padding-top: 2rem;
        padding-bottom: 2rem; 
    }

    @media (min-width: 1024px) { 
        padding-top: 3rem;
        padding-bottom: 3rem; 
    }
`;

export const Container = styled.div`
    padding-left: 1rem;
    padding-right: 1rem; 
    margin-left: auto;
    margin-right: auto; 
    max-width: 768px; 

    @media (min-width: 768px) { 
        padding-left: 2rem;
        padding-right: 2rem; 
    }
`;

export const Title = styled.h2`
    color: #ffffff; 
    font-size: 1.875rem;
    line-height: 2.25rem; 
    font-weight: 700; 
    line-height: 1.25; 
    text-align: center; 

    @media (min-width: 640px) { 
        font-size: 2.25rem;
        line-height: 2.5rem; 
    }

    @media (min-width: 1024px) { 
        font-size: 3rem;
        line-height: 1;
    }
`;

export const ImageContainer = styled.div`
    overflow: hidden; 
    position: relative; 
    margin-bottom: 1.5rem; 
    margin-top: 3rem; 
    border-radius: 0.5rem; 
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 

    @media (min-width: 768px) { 
        margin-bottom: 2rem; 
    }
`;

export const AboutImage = styled.img`
    object-fit: cover; 
    object-position: center; 
    width: 100%; 
    height: 100%; 
`;

export const AboutDescription = styled.p`
    color: #F3F4F6;

    @media (min-width: 640px) { 
        font-size: 1.125rem;
        line-height: 1.75rem; 
    }
`;

export const TeamSection = styled.section`
    padding-bottom: 2.5rem; 
    background: #111;

    @media (min-width: 640px) { 
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    @media (min-width: 1024px) { 
        padding-top: 1rem;
        padding-bottom: 6rem; 
    }
`;

export const TeamContainer = styled.div`
    padding-left: 1rem;
    padding-right: 1rem; 
    margin-left: auto;
    margin-right: auto; 
    max-width: 42rem;  

    @media (min-width: 640px) { 
        padding-left: 1.5rem;
        padding-right: 1.5rem; 
    }

    @media (min-width: 1024px) { 
        padding-left: 2rem;
        padding-right: 2rem;
    }
`;

export const TeamGrid = styled.div`
    display: grid; 
    margin-left: auto;
    margin-right: auto; 
    margin-top: 3rem; 
    max-width: 28rem; 
    grid-template-columns: repeat(1, minmax(0, 1fr)); 
    gap: 1.5rem; 

    @media (min-width: 1024px) { 
        margin-top: 4rem; 
        max-width: 100%; 
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
        column-gap: 4rem; 
        row-gap: 3rem; 
    }
`;

export const MemberImageContainer = styled.div`
    display: block; 
`;

export const MemberImage = styled.img`
    object-fit: cover; 
    width: 100%; 
    height: 16rem; 
    border-radius: 0.375rem; 
`;

export const MemberName = styled.h3`
    margin-top: 1rem; 
    color: #F3F4F6; 
    font-size: 1.5rem;
    line-height: 0.5rem; 
    font-weight: 600; 
`;

export const MemberDescription = styled.p`
    margin-top: 0.5rem; 
    color: #F3F4F6; 
    margin-bottom: 2.5rem;
`;
