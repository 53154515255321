import styled from "styled-components";

export const BannerWrapper = styled.div`
  background-color: #111;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (max-width: 780px) {
    padding: 1.5rem 0;
  }

  @media (min-width: 640px) {
    padding: 0rem 6rem 1.5rem;
  }
`;

export const BannerInner = styled.div`
  max-width: 1024px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
`;
export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #00804f;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;
export const BannerButton = styled.div`
  display: inline-block;
  background-color: #ffce3d;
  color: #000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  :hover {
    background-color: #c09411;
  }
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 100ms;
  font-weight: 600;
  text-align: center;
  border-radius: 9999px;
  outline: 0;

  @media (min-width: 768px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const BannerButton2 = styled.div`
  display: none;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #ffce3d;
  color: #000;
  font-size: 1.3rem;
  line-height: 1.25rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  :hover {
    background-color: #c09411;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const BannerText = styled.h2`
  font-size: 1.3rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: white;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 2rem;
  }
`;
export const CrownImage = styled.img`
  width: 100px;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Text = styled.p`
  color: #fff;
`;
