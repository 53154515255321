import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, Contestants, Landing, About, Blog, Statistics } from "./pages";
import { ContestantsDetails } from "./HOC";
import { Vote } from "./components";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contests",
    element: <Contestants />,
  },
  { path: "/statistics", element: <Statistics /> },
  {
    path: "contestant/:contestantId",
    element: <ContestantsDetails />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "vote/:contestantId",
    element: <Vote />,
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
