import React from "react";
import {
  LeaderBoardContainer,
  LeaderBoardImage,
  LeaderBoardImageContainer,
  LeaderBoardInner,
  LeaderBoardNumberData,
  LeaderBoardStringContainer,
  LeaderBoardStringData,
  LeaderBoardTable,
  LeaderBoardTableData,
  LeaderBoardTableTitle,
  LeaderBoardWrapper,
} from "./LeaderBoard.styled";
import data from "../../User";

const rankedContestants = data.sort((a, b) => b.votes - a.votes);

function LeaderBoard() {
  return (
    <LeaderBoardContainer>
      <LeaderBoardWrapper>
        <LeaderBoardInner>
          <LeaderBoardTable>
            <thead>
              <tr>
                <LeaderBoardTableTitle>Rank</LeaderBoardTableTitle>

                <LeaderBoardTableTitle>Contestants</LeaderBoardTableTitle>

                <LeaderBoardTableTitle>Accumulated Votes</LeaderBoardTableTitle>
              </tr>
            </thead>

            <tbody>
              {rankedContestants.map((contestant) => (
                <tr key={contestant.id}>
                  <LeaderBoardTableData>
                    <LeaderBoardNumberData>
                      {contestant.id}
                    </LeaderBoardNumberData>
                  </LeaderBoardTableData>

                  <LeaderBoardTableData>
                    <LeaderBoardStringContainer>
                      <LeaderBoardImageContainer>
                        <LeaderBoardImage src={contestant.img} alt="/" />
                      </LeaderBoardImageContainer>
                      <LeaderBoardStringData>
                        {contestant.name}
                      </LeaderBoardStringData>
                    </LeaderBoardStringContainer>
                  </LeaderBoardTableData>

                  <LeaderBoardTableData>
                    <LeaderBoardNumberData>
                      {contestant.votes
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </LeaderBoardNumberData>
                  </LeaderBoardTableData>
                </tr>
              ))}
            </tbody>
          </LeaderBoardTable>
        </LeaderBoardInner>
      </LeaderBoardWrapper>
    </LeaderBoardContainer>
  );
}

export default LeaderBoard;
