import React, { useState } from "react";
import search from "../../assets/svg/search.svg";
import { StyledHeader } from "./Header.styled";

export default function Header({ onSearch }) {
  const [value, setValue] = useState("");
  return (
    <StyledHeader
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(value);
      }}
    >
      <input
        onChange={(e) => {
          setValue(e.target.value);
          onSearch(e.target.value);
        }}
        type="search"
        placeholder="Search"
        value={value}
      />
      <img src={search} alt="" />
    </StyledHeader>
  );
}
